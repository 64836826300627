import React from "react"
import { Link, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import {Section, Container, SectionImageBkg, SectionTitle, SectionDescription, BreadCrumb } from "../components/Section"
import { HeroBanner, HeroTitle, HeroCaption, HeroContent, HeroLeft, HeroRight, HeroCard } from "../components/HeroBanner"
import JoinForm from "../components/JoinForm"

const HeroBanners = styled(HeroBanner)`
  &:before{
    bottom:50%;
    z-index:1;
    opacity: 0.020;
  }
  &:after{
    height:80%;
    background: -moz-linear-gradient(top,  rgba(23,31,49,0.99) 70%, rgba(255,255,255,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(23,31,49,0.99) 70%,rgba(255,255,255,0) 100%);
    background: linear-gradient(to bottom,  rgba(23,31,49,0.99) 70%,rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171F31', endColorstr='#00ffffff',GradientType=0 );
  }
`
const HeroCaptions = styled(HeroCaption)`	
  padding:100px 0;
  @media (min-width:1200px){
    padding:160px 0;
  }
`
const SectionInstallation = styled(Section)`	
  overflow:hidden;
  .container{
    @media (min-width: 1410px){
      max-width: inherit;
      padding-right: calc((100% - 1410px) / 2);
      padding-left: 0;
    }
  }
`
const InstallationCircle = styled.div`	
  position:relative;
  width:770px;
  height:770px;
  border-radius:50%;
  background-color:#161420;
  display:flex;
  align-items:center;
  justify-content:flex-end;
  left: -220px;
  &:after{
    content: '';
    border: 1px solid #BFD7E3;
    position: absolute;
    width: calc(100% + 80px);
    height: calc(100% + 80px);
    border-radius: 50%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .gatsby-image-wrapper{
    position:relative;
    z-index:1;
    margin-right:-60px;
  }
`

const ListInfo = styled.div`
`
const ListItemInfo = styled.div`
  position:relative;
  &:after, &:before{
    @media(min-width:1200px){
      content:'';
      position:absolute;
      top:50%;
      transform:translateY(-50%);
    }
  }
  &:after{
    width: 14px;
    height: 14px;
    border: 4px solid #F48219;
    border-radius: 50%;
    background-color: #fff;
    left: -198px;
  }
  &:before{
    width:198px;
    height:4px;
    background-color:#161420;
    right:100%;
  }
  .card{
    position:relative;
    &:before{
      content:'';
      position:absolute;
      top:0;
      left:0;
      bottom:0;
      background-color:transparent;
      width:24px;
      height:100%;
      border-radius:15px 0 0 15px;

    } 
  }
  &:nth-child(1){
    &::after{
      border-color:#F48219;
      left: -350px;
    }
    &:before{
      width:350px;
    }
    .card{
      &:before{
        background-color:rgba(244,130,25,0.3);
      }
    }
  }
  &:nth-child(2){
    &::after{
      border-color:#E44BA4;
    }
    .card{
      &:before{
        background-color:rgba(228,75,164,0.3);
      }
    }
  }
  &:nth-child(3){
    &::after{
      border-color:#6F5DC6;
    }
    .card{
      &:before{
        background-color:rgba(111,93,198,0.3);
      }
    }
  }
  &:nth-child(4){
    &::after{
      border-color:#53C3CA;
      left: -350px;
    }
    &:before{
      width:350px;
    }
    .card{
      &:before{
        background-color:rgba(83,195,202,0.3);
      }
    }
  }
  + .item{
    margin-top:30px;
    @media(min-width:1200px){
      margin-top:80px;
    }
  }
  
`
const CardInfo = styled.div`
  background-color:#EBEFF7;
  border-radius:15px 15px 118px 15px;
  display:flex;
  align-items:center;
  @media(min-width:1200px){
    min-height:148px;
  }
`
const CardInfoBody = styled.div`
  font-weight:700;
  padding:30px 30px 30px 40px;  
  font-size:18px;
  line-height:32px; 
  @media(min-width:1200px){
    padding:30px 60px 30px 60px;
  }
  @media(min-width:1600px){
    padding:30px 120px 30px 60px;
    font-size:24px;
    line-height:34px;
  }
  
`
const Grid = styled.div`
  display:flex;
  align-items:center;  
`
const GridItemLeft = styled.div`
  display:none;
  @media(min-width:1200px){
    display:flex;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
`
const GridItemRight = styled.div`
  position:relative;
  width:100%;
  @media(min-width:1200px){
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;  
  }
`

const CoverageAreaPage = ({ data, location }) => {
  const pageData = data.contentfulPageCoverageArea
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <Section bgColor="#171F31" bgBefore="#171F31" bgBeforeHeight="100%"  bgBeforeWidth="100%">
        <HeroBanners className="banner-hero" bgBefore="block" data-shadowtext="Coverage">
          <SectionImageBkg><StaticImage src="../images/map-address.png" alt="map-address" /></SectionImageBkg>
          <HeroCaptions>
            <Container maxWidth="1640px">
            <BreadCrumb><Link to='/'>Home</Link><span>Coverage Area & Lead Times</span></BreadCrumb>
              <HeroContent>
                <HeroLeft><HeroTitle maxWidth="490px">{pageData.heroTitle}</HeroTitle></HeroLeft>
                <HeroRight><HeroCard><JoinForm location={location} /></HeroCard></HeroRight>
              </HeroContent>
            </Container>
          </HeroCaptions>
        </HeroBanners>
      </Section>

      <Section ept="80px" epb="60px" xpt="60px" xpb="40px" pt="40px" pb="0"  bgColor="#F8FAFB" className="section-area">
        <Container maxWidth="1280px">
          <SectionTitle ml="0">{pageData.sectionTitle} </SectionTitle>
          <SectionDescription maxWidth="1100px" ml="0" mb="0">
            <div dangerouslySetInnerHTML={{__html: pageData.sectionDescription.childMarkdownRemark.html}} /> 
          </SectionDescription>
        </Container>
      </Section>

      <SectionInstallation ept="60px" epb="120px" xpt="60px" xpb="60px" pt="60px" pb="40px"  overflow="hidden"  bgColor="#F8FAFB" className="section-crew">      
        <Container className="container">
          <Grid>
            <GridItemLeft>
              <InstallationCircle>
                <GatsbyImage image={pageData.featureImage.gatsbyImageData} alt={pageData.featureImage.title} />
              </InstallationCircle>
            </GridItemLeft>
            <GridItemRight>
              <ListInfo>
                {pageData.features.map((feature, i) => (
                  <ListItemInfo className="item" key={i}>
                    <CardInfo className="card"><CardInfoBody className="card-body">{feature.content}</CardInfoBody></CardInfo>
                  </ListItemInfo>
                ))}
              </ListInfo>
            </GridItemRight>
          </Grid>
        </Container>
      </SectionInstallation>
    </Layout>
  )
}

export default CoverageAreaPage

export const pageQuery = graphql`
  query CoverageAreaPageQuery {
    contentfulPageCoverageArea {
      metaTitle
      metaDescription
      heroTitle
      sectionTitle
      sectionDescription {
        childMarkdownRemark {
          html
        }
      }
      features {
        content
      }
      featureImage {
        title
        gatsbyImageData(quality: 50, placeholder: BLURRED)
      }
    }
  }
`